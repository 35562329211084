@import "/constants/_colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type="file"] {
  width: 100%;
}

@font-face {
  font-family: "Baloo2-Bold";   /*Can be any text*/
  src: local("Baloo2-Bold"),
    url("../fonts/Baloo/Baloo2-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Baloo2-ExtraBold";   /*Can be any text*/
  src: local("Baloo2-ExtraBold"),
    url("../fonts/Baloo/Baloo2-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Baloo2-Medium";   /*Can be any text*/
  src: local("Baloo2-Medium"),
    url("../fonts/Baloo/Baloo2-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Baloo2-Regular";   /*Can be any text*/
  src: local("Baloo2-Regular"),
    url("../fonts/Baloo/Baloo2-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Baloo2-SemiBold";   /*Can be any text*/
  src: local("Baloo2-SemiBold"),
    url("../fonts/Baloo/Baloo2-SemiBold.ttf") format("truetype");
}

