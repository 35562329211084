@import "/constants/_colors.scss";
@import "/constants/_buttons.scss";

:root{
  --background-profile: linear-gradient(to bottom, blue, white);
 }

.header {
  z-index: 101;
  height: 101px;
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: space-between;
  position: fixed;
  grid-template-columns: auto 1fr max-content;
  background-color: $black;
  font-family: "Source Sans Pro", sans-serif;

  .profileBtn {
    margin: 0 15px;
    color: white;
    padding: 1em;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: var(--background-profile);
  }

  .activeTab {
    color: white;
    font-weight: 600;
    font-size: 15px;
  }

  .socialIcons {
    padding-left: 1em;
    gap: 0.4em;

    a {
      height: 30%;
      // min-height: 24px;
      margin-right: 0.25em;
    }
  }

  /* no-descending-specificity */
  .linkListDesktop a, .linkListDesktop .parentMenuTitle {
    color: $lighterGray;
    text-decoration: none;
    transition: color 0.1s linear;
    white-space: nowrap;
    cursor: pointer;
  }

  .linkListDesktop p {
    padding: 0.5em;
    font-weight: 500;
    font-size: 15px;
  }

  .profileDropdown ul a {
    display: block;
    padding: 15px;
    border-bottom: 1px solid #333333;
  }

  .linkListMobile .dropDownContent a {
    padding: 16px;
  }

  .linkListDesktop .dropDownContent a {
    text-decoration: none;
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #333333;
    padding: 12px 16px;
  }
  /* end no-descending-specificity */

  .menuIconContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 20px;

    .menuIcon {
      font-size: 20px;
      color: #ABABAB;
    }
  }  

  .getStartedButton {
    @extend .buttonRegularOrange;
    text-align: center;
    width: 110px;
    font-size: 14px;
    cursor: pointer;
    background: linear-gradient(to right, #F16422, #FAAE31);
    border-color: hsla(20, 70%, 50%, 1);
    

    &:hover {
      border-color: $uiOrange;
    }

    &.signout {
      padding: 1px;
    }

    p {
      padding: 0.5em;
      color: #fff;
      font-weight: 600;
    }
  }  

  .profileDropdown{
    position: absolute;
    top: 100px;
    background: #1e1e1e;
    color: #D6d6d6;
    width: 200px;
    font-size:14px;

    ul{
      margin: 0;
      padding: 0;

      .selected{
        color: $uiOrange;
      }

      li{
        list-style: none;
        padding: 15px;
        cursor: pointer;

        &:hover{
          color: $uiOrange;
          background-color: $mediumDarkGray;
        }

        p{
          padding: 0;
        }
      }
    }
  }

  .linkListMobile {
    display: flex;
    position: absolute;
    top: 90px;
    width: 100%;
    height: 100vh;
    background-color: transparent;

    .dropDownContent {
      display: grid;
      grid-area: auto;
      padding: 0 20px;

      &.hidden {
        display: none;
        transition: transform 0.3s ease-in-out;
      }
    }

    .listContainer {
      width: 100%;
      height: fit-content;
      background-color: $lightBlack;
      padding: 0 30px;
      align-content: center;
      justify-content: center;
      font-size: 20px;
      
      
      .parentMenuTitle,
      a,
      > p {
        width: 100%;
        font-size: 16px;
        color: #ABABAB;
        text-decoration: none;
        transition: color 0.1s linear;
        cursor: pointer;
        height: fit-content;
        padding: 0.5em;
      }

      .loginNavLink{
        padding: 0;
      }

      .signInWrapper{
        padding: 0.5em;
      }
    }

    .menuTitleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px dotted;

      .menuIcon {
        cursor: pointer;
      }
    }
  }

  .linkListDesktop {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    padding: 0 20px;

    .button {
      margin-left: 15px;
      width: 110px;
      height: 38px;
      font-size: 14px;
      font-weight: 400px;
      box-sizing: border-box;
    }

    .menuTitleContainer {
      display: flex;
      align-items: center;
    }

    .dropDownContent {
      visibility: hidden;
      position: absolute;
      background-color: $lightBlack;
      min-width: 180px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      font-size: 14px;
      opacity: 0;
    }

    .dropDown {
      position: relative;
      display: inline-block;
      margin: 0.6em;
    }
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    padding-left: 40px;
    outline: none;
  }

  .logo{
    width: 250px;
  }

  .signingInLoaderContainer {
    display: flex;
    align-items: center;
    width: 150px;

    img {
      width: 40px;
      margin-right: 5px;
    }
  }

  .signInContainer {
    //background: none;
    background: black;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d6d6d6;
    border-radius: 4px;

    &:hover {
      border-color: $uiOrange;
    }    
  }

  @media (max-width: 1200px) {
    .logoWrapper {
      padding-left: 20px;
    }

    .socialIcons {
      padding-left: 0.6em;
      gap: 0.3em;
    }

    .linkListDesktop {
      padding: 0 10px;

      .dropDown {
        margin: 0;
        p {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .linkListDesktop {
      font-size: 3px;
    }

    .linkListDesktop p{
      font-size: 13px;
    }

    .linkListDesktop .button{
      width: 110px;
      height: 31px;
    }
  }

  @media (min-width: 991px) {
    .linkListMobile,
    .menuIconContainer {
      display: none;
    }
  }

  @media (max-width: 990px) {

    .linkListDesktop {
      display: none;
    }

    .socialIcons {
      display: none;
    }

    .linkListMobile,
    .menuIconContainer {
      display: flex;
      padding-right: 20px;
    }

    .welcomeMessage {
      display: none;
    }

    .signInContainer {
      width: 100%;
      text-align: left;
      font-size: 14px;
      cursor: pointer;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 1px;
    }
  }

  @media all and (max-width: 420px) {
    .signingInLoaderContainer {
      visibility: hidden;
    }
  }

  /* no-descending-specificity */
  .profileDropdown ul a:hover{
    background-color: $mediumDarkGray;
  }

  .linkListDesktop .dropDownContent a:hover {
    color: $uiOrange;
  }  

  .linkListDesktop .dropDown:hover {
    .dropDownContent {
      display: block;
      visibility: visible;

      opacity: 1;
      transition: 0.5s;

      a {
        color: $lighterGray;
        font-weight: 500;
        font-size: 14px;
        padding: 12px 16px;

        &:hover {
          color: $uiOrange;
          background-color: #333333ad;
        }
      }
    }
  }

  .getStartedButton p:hover {
    color: $uiOrange;
  }

  .linkListMobile .listContainer p:hover {
    color: $uiOrange;
  }
  /* end no-descending-specificity */
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .header {
    display: flex;
  }

  .listContainer {
    display: table;
    padding: 20px;
    text-align: left;

    p,
    a {
      margin: 0.5px;
    }
  }

  .linkListMobile {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    left: 0;
  }

  .menuIconContainer {
    display: none;
  }

  .menuIcon {
    font-size: 30px;
    color: #e1e1e1;
    opacity: 0.35;
    margin-bottom: 50px;
    
  }

  .signInContainer {
    p {
      padding: 0px;
    }
  }
}

@media (max-width: 990px) {
  .header {
    grid-template-columns: auto max-content;
  }
}
