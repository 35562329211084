@import "/constants/_colors.scss";

.App {
  background-color: #222222;
}

html {
  height: 100%;
  overscroll-behavior: none;
  background-color: #222222;

}

body {
  height: auto;
  overscroll-behavior: none;
}

//mobile screens
@media screen and (max-width: 767px) { 
  html {
      overflow-x: hidden;
    }
}

