@import "./_colors.scss";

.buttonShared {
  color: $white;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  transition: background-color 0.2s linear;
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  font-family: "Source Sans Pro", sans-serif;
}

.buttonDisabled{
  @extend .buttonShared;

  background-color: #2f2f2f;
  cursor:not-allowed;
}

.buttonGhost {
  @extend .buttonShared;

  &:hover {
    background-color: $white;
  }

  &:active {
    background-color: scale-color($white, $lightness: -5%);
  }
}

.buttonRegularOrange {
  @extend .buttonShared;

  background-color: $uiOrange;

  &:hover {
    background-color: scale-color($uiOrange, $lightness: +15%);
  }

  &:focus {
    background-color: scale-color($uiOrange, $lightness: +15%);
  }
}

.buttonRegularYellow {
  @extend .buttonShared;

  background-color: $uiYellow;

  &:hover {
    background-color: scale-color($uiYellow, $lightness: +15%);
  }

  &:focus {
    background-color: scale-color($uiYellow, $lightness: +15%);
  }
}

.buttonGradientOrange {
  @extend .buttonShared;

  background-image: linear-gradient(to right, $uiOrange, $uiYellow);

  &:hover {
    background-image: linear-gradient(to right, #FF5805, #FF9E00);
  }

  &:focus {
    background-image: linear-gradient(to right, #FF5805, #FF9E00);
  }
}

.buttonReglarGrey {
  @extend .buttonShared;

  background-color: $mediumGray;

  &:hover {
    background-color: $mediumLightGray;
  }

  &:focus {
    background-color: $darkGray;
  }
}

.buttonReglarDarkGrey {
  @extend .buttonShared;

  background-color: $mediumDarkGray;
  color: #ABABAB;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  
  &:hover {
    background-color: $mediumLightGray;
  }

  &:focus {
    background-color: $darkGray;
  }
}

.buttonGhostGrey {
  @extend .buttonGhost;
  background: $white;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  color: #a1a1a1;

  &:hover {
    color: $mediumLightGray;
  }

  &:active {
    color: $mediumLightGray;
  }
}

.buttonGhostBlue {
  @extend .buttonGhost;
  background-color: $uiBlue;

  border-width: 2px;
  border-color: $uiBlue;
  border-style: solid;

  &:hover {
    color: $uiBlue;
  }

  &:active {
    color: $uiBlue;
  }
}

.buttonGhostDarkBlue {
  @extend .buttonGhost;
  background-color: $uiDarkBlue;

  border-width: 2px;
  border-color: $uiDarkBlue;
  border-style: solid;

  &:hover {
    color: $uiBlue;
  }

  &:active {
    color: $uiBlue;
  }
}

.buttonGhostOrange {
  @extend .buttonGhost;
  background-color: $white;
  color: $uiOrange;
  border-width: 2px;
  border-color: $uiOrange;
  border-style: solid;

  &:hover {
    background-color: $uiOrange;
    color: $white;
  }

  &:active {
    background-color: $uiOrange;
    color: $white;
  }
}

.buttonGhostGradientOrange {
  @extend .buttonGhost;
  background-color: $white;
  color: $uiOrange;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(to right, $uiOrange, $uiYellow);
  border-image-slice: 1;

  &:hover {
    color: $uiOrange;
  }

  &:active {
    color: $uiOrange;
  }
}

.buttonGhostGreen {
  @extend .buttonGhost;
  background-color: $uiGreen;

  border-width: 2px;
  border-color: $uiGreen;
  border-style: solid;

  &:hover {
    color: $uiGreenAlt;
    border-color: $uiGreenAlt;
  }

  &:active {
    color: $uiGreenAlt;
    border-color: $uiGreenAlt;
  }
}

.buttonGhostDisabled {
  @extend .buttonGhost;
  background-color: #d0dadb;
  color: white;
  cursor: not-allowed;
  border: 1px solid white;
}


.clientLoginText {
  font-family:  -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  color: #ABABAB;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  padding: 25px 0 20px 0;
  max-width: 250px;

  &.error {
    color: #CE3530;
  }

  &.link {
    cursor: pointer;
    text-decoration: underline;

    .arrow {
      margin-right: 6px;
    }

    &:hover{
      color: #fff;
    }
  }

  &.signOut {
    padding: 10px 0;
  }

  @media screen and (max-width: 768px) {
      color: #4B4B4B;
      max-width: 284px;
  }

}
.signOutText {
  color: $uiOrange;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}
.returnToSignIn{
  @extend .clientLoginText;
  padding: 20px 0px;


  &:hover{
    color: #fff;
  }
}


.buttonVerticalGradientOrange {
  @extend .buttonShared;

  background-image: linear-gradient(to top, $uiOrange, $uiYellow);

  &:hover {
    background-image: linear-gradient(to top, #FF5805, #FF9E00);
  }

  &:focus {
    background-image: linear-gradient(to top, #FF5805, #FF9E00);
  }
}



.buttonVerticalGradientOrangeYellow {
  @extend .buttonShared;

  background-image: linear-gradient(to top, #ff8f05, rgb(255, 255, 32));

  &:hover {
    background-image: linear-gradient(to top, #FF5805, #FF9E00);
  }

  &:focus {
    background-image: linear-gradient(to top, #FF5805, #FF9E00);
  }
}