@keyframes dance {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.animatedButton {
    animation: dance 2s infinite;
}
