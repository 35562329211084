@import "/_colors.scss";

.linkRegular {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: $uiOrange;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s ease-in;

  &:hover {
    color: scale-color($uiOrange, $lightness: 35%);
  }
}


.clientDefaultText {

  color: #898989;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 240px;
  padding-bottom: 18px;
  white-space: pre-line;
}