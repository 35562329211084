@import "/constants/_colors.scss";
@import "/constants/_misc.scss";

.footer {
  width: 100%;
  background-color: $black;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  // position: relative;
  bottom: 0;
  z-index: 10;

  a {
    @extend .linkRegular;

    color: white;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  label {
    @extend .linkRegular;
    color: white;
    font-size: 12px;
    line-height: 20px;
  }  

  .wrapper {
    position: relative;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // max-width: 1100px;
    margin: 0 auto;
  }

  .linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    // background-color: white;
    width: 100%;

    @media screen and (max-width: 820px) {
      padding-top: 32px;
      flex-direction: column;
    }
  }

  .linksWrapper {
    display: flex;
    @media screen and (max-width: 1284px){
      flex-direction: column;
    }
  }

  .socialText {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    color: white;
    margin-right: 2em;
    font-size: 2em;
    margin-bottom: 0;

    a {
      color: white !important;
      font-size: .7em !important;
      line-height: 1em !important;
      text-align: left !important;
      align-items: left !important; 
      margin-bottom: .5em;
    }
  }

  .linkItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    min-width: 200px;
    width: 100%;
    box-sizing: border-box;
    color: white;

    label {
      font-size: 1em;
      line-height: 1.4em;
    }
  
    @media screen and (max-width: 1284px) {
      margin: 0;
      padding: 10px;
      width: 100%;
    }

    .privacyTerms {
      font-size: 12px;

      a {
        text-decoration: underline;
      }
    }
  }


  .linkTitle {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 3px;
  }




  #ot-sdk-btn {
    color: white;
    cursor: "pointer";
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
  }

  p,span {
    color: white;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  .blogLinks {
      text-decoration: underline;
  }

  .interestsList {
    list-style-type: none;
    padding: 0;

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;

      input[type=checkbox] {
        margin-right: 1em;
        width: 24px;
        height: 24px;
        flex-grow: 0;
      }
    }
  }

  .termsContainer {
    // max-width: 1030px;
    width: 100%;
    bottom: 12px;
    // position: absolute;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */

    @media screen and (min-width: 768px){
      left: 32px;
      text-align: left;
      display: flex;
      min-width: 300px;
      max-width: 350px;
      justify-content: space-evenly;
    }
  }

  form {
    .formInputs{
      display: flex;
      flex-direction: row;
      padding: 2px;
      // width: 100%;
      width: 500px;
      height: 50px;

      @media screen and (max-width: 768px){
        width: 100%;
      }
    }

    input {
      font-family: "Source Sans Pro", sans-serif;
      flex-grow:2;
      background: transparent;
      border-radius: 2px;
      border: 1px solid gray;
      max-width: 250px;
      color: white;
      padding-left: 10px;
    }

    input:focus {
      outline: none;
    }
    
    button {
      font-family: "Source Sans Pro", sans-serif;
      background:$uiOrange;
      color: white;
      width: 8.4em;
      border: none;
      outline: none;
      cursor: pointer;

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
    }
  }
    
  .socialIcons {
    margin-bottom: 12px;

    > * {
      margin-bottom: 0;
    }
  }

  .socialLinkVertical {
    color: white !important;
    box-sizing: border-box;
    opacity: 0.5;

    height: 100%;
    text-align: left !important;

    img {
      height: 100%;
    }
  }

  .textSection {
    gap: 0.6em;
    width: 100%;
    box-sizing: border-box;
  }

  @media screen and (max-width: 378px){

    .termsContainer {
      justify-content: center;
      p,
      span,
      a
      {
        font-size: 12px;
        padding: 0 2px;
      }
    }
  } 


  .linkItemsSocial {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 16px;
    text-align: left;
    // min-width: 200px;
    box-sizing: border-box;
    color: white;
  
    @media screen and (max-width: 1284px) {
      margin: 0;
      padding: 10px;
      width: 100%;
    }

    .privacyTerms {
      font-size: 12px;

      a {
        text-decoration: underline;
      }
    }
  }
}
