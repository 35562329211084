%base {
    font-family: "Source Sans Pro", sans-serif;
}

%centeredPageLayout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}