.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  min-height: 300px;
  // height: auto;
  aspect-ratio: 4/5;
  // max-width: 450px;
  max-height: 500px;
  width: auto;
  margin: auto;
  // max-height: 500px;
  // max-height: 700px;
}

.previewBitContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  justify-content: space-between;
  // flex: 1;
  width: 100%;
  height: 100%;
}

.bgInfoBase {
  min-height: 100px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("https://frontend-assets.buildbox.com/Text_Frame.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin-top: 2em;
  top: 0;
}

.textInfoTitle{
  width: 70%;
  font-size: 2em;
}
.textInfoMission{
  width: 70%;
  font-size: 1em;
}

.startImgContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 80%;
  max-width: 250px;
  height: auto;
}

.startImg{
  width: 100%;
  height: 100%;
  margin-bottom: 2em;
}

@media only screen and (max-width: 768px){
  .textInfoTitle{
    line-height: 1;
  }
  .textInfoMission{
    width: 80%;
    font-size: 1em;
    line-height: 1.2;
  }

}



@media only screen and (max-width: 420px){
  .textInfoTitle{
    line-height: 1;
    font-size: 1.2em;
  }


  .bgInfoBase{
    margin-top: .5em;
  }
  .textInfoMission{
    width: 80%;
    font-size: .8em;
    line-height: 1.2;
  }

  .startImgContainer{
    margin-top: .5em;
  }

}