.promptMessage {
  color: #fff;
  padding: 0.5em;
}

.senderImg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.promptImgContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.promptImg {
  width: 100px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
}

.promptImgActive {
  border: 2px solid #F9AA30;
  border-radius: 5px;
}

.promptDownload {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.promptFeedbackUser {
  background-color: #222222;
  border-bottom: 0.1px solid #505050;
}

.promptFeedbackBBAI {
  background-color: #323232;
  border-bottom: 0.1px solid #222222;
}
.fancyText {
  color: #F9AA30;
  text-decoration: underline;
  cursor: pointer;
}

.promptImgLandscape {
  width: 140px;
  height: 100px;
}

@media (max-width: 434px) {
  .promptImgContainer {
    justify-content: center;
  }
  .promptImg {
    /* flex: 1 0 33.33%; */
    flex-basis: 33.33%;
  }
 }

 .qrContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  /* margin: 15px 0px; */
  margin: 1em 0em;
 }