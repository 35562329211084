.socialLinkVertical {
  color: white !important;
  box-sizing: border-box;
  opacity: 0.5;

  height: 100%;
  text-align: left !important;

  img {
    height: 100%;
  }
}

.socialIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.6em;
  width: 100%;
  box-sizing: border-box;

  .socialLink {
    box-sizing: border-box;
    opacity: 0.5;

    // this seems like overkill, but I was struggling to get vertical-align to work in this context
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    img {
      height: 100%;
    }
  }
}

