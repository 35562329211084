@import "/constants/_colors.scss";
@import "/constants/_buttons.scss";
@import "/constants/_shared.scss";

.container {
  @extend %centeredPageLayout;

  text-align: center;
  background-color: $lightBlack;
  color: white;
}
.header {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 12px;
    font-weight: 300;
    padding-bottom: 16px;
    max-width: 450px;
    white-space: pre-wrap;
  }
  p {
    margin-top: 14px;
    font-size: 14px;
    max-width: 450px;
    font-weight: 600;
  }
  .errorMessage {
    color: $uiRed;
  }
  .BBLogo {
    height: 45px;
    width: 206px;
    padding-bottom: 10px;
  }
  .redirectButton {
    background: none;
    color: #ABABAB;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    border: 1px solid #3A3A3A;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    width: 130px;
    height: 30px;
    margin-top: 46px;
  }
}

.reloadBtn {
  width:30px
}
@media (max-width: 700px) {
  .container {
    padding-top: 100px;
  }
  h3 {
    font-size: 35px;
    white-space: nowrap;
  }
}
@media (max-width: 500px) {
  .header {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
      max-width: 350px;
    }
    .BBLogo {
      height: 130px;
      padding-bottom: 20px;
    }
  }
}

.specialClientConnectionContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $lightBlack;
  color: white;

  .BBLogo {
    width: 200px
  }
}